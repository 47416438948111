.main {
  padding: 6rem 2.25rem 3rem;
  text-align: center;
}

.lead {
  font-size: 18px;
  line-height: 1.25;
  color: #6c757d !important;
}

.main img {
  width: 125px;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.img-polaroid {
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
