.footer {
  font-size: 90%;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
}

/* Social Icons */
.social a {
  text-decoration: none;
  color: rgb(153, 153, 153);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.social a:hover {
  color: rgb(23, 77, 89);
  text-decoration: none;
}
.social a:after {
  content: ' / ';
}
.social a:last-child:after {
  content: '';
}
